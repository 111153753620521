// import axios from "../../../utils/axios";
import { apiConfig } from "src/config";
import axios from "src/utils/axios";
import { ApiPaths } from "../../../utils/api-paths";
//

export function requestAuthorisation(data: any) {
  return axios.post(ApiPaths.authorization, data);
}

export function requestLogin(data: any) {
  return axios.post(ApiPaths.login, data, apiConfig());
}

export function requestGetProfile() {
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const jwtToken =
    typeof window !== "undefined" ? localStorage.getItem("jwtToken") : "";
  return axios.get(ApiPaths.getProfile, apiConfig());
}

// update profile
export function requestUpdateProfile(data: any) {
  return axios.put(ApiPaths.updateProfile, data, apiConfig());
}

// Lost password
export function requestPasswordLost(data: any) {
  return axios.post(ApiPaths.lostPassword, data, apiConfig());
}

// Lost password
export function requestResetPassword(data: any) {
  return axios.post(ApiPaths.resetPassword, data, apiConfig());
}

// Edit Notifications Preferences
export function requestEditNotificationsPreferences(data: any) {
  return axios.put(ApiPaths.editNotificationsPreferences, data, apiConfig());
}
