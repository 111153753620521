import { useRef, useState } from "react";

import { NavLink } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";

import InboxTwoToneIcon from "@mui/icons-material/InboxTwoTone";
import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import { useDispatch, useSelector } from "src/redux/store";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useLocales from "src/locales/use-locales";
import { paths } from "src/routes/paths";
import { init } from "src/redux/slices/auth";
import { setSession } from "src/utils/utils";
const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    color: ${theme.colors.alpha.black[50]}; /* Changement de couleur */
  `
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.colors.alpha.black[100]};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.colors.alpha.black[70]}
`
);

function HeaderUserbox() {
  const { t } = useLocales();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  /* const user = {
    name: "Catherine Pike",
    avatar: "/static/images/avatars/1.jpg",
    jobtitle: "Project Manager",
  }; */

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogOut = () => {
    setSession(null);
    dispatch(init({ user: null, authenticated: false }));
  };

  return (
    <>
      <UserBoxButton
        color='inherit'
        ref={ref}
        onClick={handleOpen}
      >
        <Avatar
          variant='rounded'
          alt={user?.first_name}
          src={user?.avatar}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant='body1'>
              {user?.first_name} &nbsp;
              {user?.last_name}
            </UserBoxLabel>
            <UserBoxDescription variant='body2'>
              {user?.role}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ArrowDropDownIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox
          sx={{ minWidth: 210 }}
          display='flex'
        >
          <Avatar
            variant='rounded'
            alt={user?.first_name}
            src={user?.avatar}
          />
          <UserBoxText>
            <UserBoxLabel variant='body1'>
              {user?.first_name} &nbsp;
              {user?.last_name}
            </UserBoxLabel>
            <UserBoxDescription variant='body2'>
              {user?.role}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List
          sx={{ p: 1 }}
          component='nav'
        >
          {/*<ListItem button to={paths.dashboard.account} component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t("accountPage.account")} />
          </ListItem> */}
          <ListItemButton
            component='a'
            href={paths.dashboard.account}
          >
            <Stack direction='row'>
              <AccountBoxTwoToneIcon
                fontSize='small'
                //sx={{ textAlign: "center" }}
              />
              <ListItemText
                sx={{ ml: 1, textAlign: "center" }}
                primary={t("accountPage.account")}
              />
            </Stack>
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color='error'
            fullWidth
            onClick={() => handleLogOut()}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t("SidebarMenu.logOut")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
