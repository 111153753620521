// export const CHECK_EXPIRY_TOKEN = 1000 * 60; // 1000 milliseconds = 1 second
export const CHECK_EXPIRY_TOKEN = 1000; // 1000 milliseconds = 1 second
export const REFRESH_DRIVER_LIST_TIME = 120000; // 2 minutes

export const VEHICULES_TYPES = {
  taxi: "TAXI",
  vsl: "VSL",
  ambulance: "AMBULANCE",
};

export const VEHICULE_TYPE_ID = {
  taxi: "f16d2740-38f8-4e56-8089-0d275f62da44",
  ambulance: "0e2a2bb9-f0d1-4996-8fd2-c6b14ec14d8f",
};

export const MISSION_STATUS = {
  PENDING: "PENDING",
  DONE: "DONE",
  IN_PROGRESS: "IN_PROGRESS",
  WAITING: "WAITING",
  OPEN: "OPEN",
  CANCELLED_BY_CARRIER: "CANCELLED_BY_CARRIER",
  CANCELLED_BY_BOOKER: "CANCELLED_BY_BOOKER",
};

export const NOTIFICATIONS_TYPES = {
  MISSION_ACCEPTED: "MISSION_ACCEPTED",
  MISSION_CANCELLED: "MISSION_CANCELLED",
  MISSION_BEGIN: "MISSION_BEGIN",
  MISSION_DONE: "MISSION_DONE",
  NEW_MISSION: "NEW_MISSION",
};

export const DEFAULT_CENTER = {
  LAT: 45.421532,
  LNG: -75.699577,
};
