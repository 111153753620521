import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Stack } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useLocales from "src/locales/use-locales";
import { CHECK_EXPIRY_TOKEN } from "src/utils/const";
import { authorisation } from "src/redux/slices/auth";
import { useDispatch } from "src/redux/store";
import { CLIENT_ID, CLIENT_SECRET, GRANT_TYPE, HOST_API } from "src/config";

const AuthLayout = ({ children }) => {
  const { t } = useLocales();
  const dispatch = useDispatch();

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [activeStep, setActiveStep] = React.useState(0);

  const images = [
    {
      label: "Taxi services",
      imgPath: "/static/images/auth/taxi-service.png",
    },
    {
      label: "Ambulance location",
      imgPath: "/static/images/auth/ambulance-location.png",
    },
    {
      label: "hospital",
      imgPath: "/static/images/auth/hospital.png",
    },
  ];

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("token") || null;
      if (token !== null) {
        const expiration = localStorage.getItem("token_expiration");
        const currentTime = Date.now();
        const expirationTime = parseInt(expiration);

        if (currentTime >= expirationTime) {
          const formData = new FormData();
          formData.append("grant_type", GRANT_TYPE);
          formData.append("client_id", CLIENT_ID);
          formData.append("client_secret", CLIENT_SECRET);
          dispatch(
            authorisation({
              dataToSend: formData,
            })
          );
        }
      } else {
        const formData = new FormData();
        formData.append("grant_type", GRANT_TYPE);
        formData.append("client_id", CLIENT_ID);
        formData.append("client_secret", CLIENT_SECRET);
        dispatch(
          authorisation({
            dataToSend: formData,
          })
        );
      }
    }, CHECK_EXPIRY_TOKEN);

    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      component='main'
      direction='row'
      sx={{
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
              component='img'
              sx={{
                direction: "column",
              }}
              src='/static/images/logo/logo-with-title.png'
            />
          </Stack>

          {children}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            background: "linear-gradient(-20deg, #1068c2 30%, #05a195 90%)",
            padding: "20px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Stack
            flexDirection='row'
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <IconButton
              sx={{ margin: 3 }}
              onClick={() => handleStepChange(activeStep - 1)}
              disabled={activeStep === 0}
            >
              <ArrowBackIosNewIcon
                sx={{ color: activeStep === 0 ? "#f5f5f570" : "#f5f5f5" }}
                fontSize='small'
              />
            </IconButton>
            <Box sx={{ maxWidth: 500, flexGrow: 1 }}>
              <AutoPlaySwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component='img'
                        sx={{
                          height: 500,
                          display: "block",
                          // maxWidth: 400,
                          overflow: "hidden",
                          width: 500,
                          alignItems: "center",
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            </Box>
            <IconButton
              sx={{ margin: 3 }}
              onClick={() => handleStepChange(activeStep + 1)}
              disabled={activeStep === 2}
            >
              <ArrowForwardIosIcon
                sx={{ color: activeStep === 2 ? "#f5f5f570" : "#f5f5f5" }}
                fontSize='small'
              />
            </IconButton>
          </Stack>

          <Typography
            variant='h3'
            sx={{ mt: 5, color: "white" }}
          >
            {activeStep === 1
              ? t("swipeableDescription.secondDescription")
              : activeStep === 2
              ? t("swipeableDescription.thirdDescription")
              : t("swipeableDescription.firstDescription")}
          </Typography>
          <Typography
            variant='body1'
            sx={{ mt: 1, color: "#ffffff75" }}
          >
            {t("swipeableDescription.title")}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AuthLayout;
