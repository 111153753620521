import { createSlice } from "@reduxjs/toolkit";
import { IReservationState } from "src/types/reservation";

const initialState: IReservationState = {
  isLoading: false,
  loadingOperation: false,
  error: null,
  patients: [],
  reservations: [],
  refresh: 0,
};

const slice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    // add reservation
    addReservation(state, action) {
      state.loadingOperation = true;
    },
    addReservationSuccess(state, action) {
      state.loadingOperation = false;
      state.error = null;
      state.refresh += 1;
    },
    addReservationFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },

    // add reservation multiple patients
    addReservationMultiplePatients(state, action) {
      state.loadingOperation = true;
    },
    addReservationMultiplePatientsSuccess(state, action) {
      state.loadingOperation = false;
      state.error = null;
      state.refresh += 1;
    },
    addReservationMultiplePatientsFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },

    // get reservations
    getListReservation(state, action) {
      state.isLoading = true;
    },
    getListReservationSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.reservations = action.payload;
    },
    getListReservationFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // cancel reservation
    cancelReservation(state, action) {
      state.loadingOperation = true;
    },
    cancelReservationSuccess(state, action) {
      state.loadingOperation = false;
      state.error = null;
      state.refresh += 1;
    },
    cancelReservationFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },

    // add reservation
    addReview(state, action) {
      state.loadingOperation = true;
    },
    addReviewSuccess(state, action) {
      state.loadingOperation = false;
      state.error = null;
    },
    addReviewFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },
  },
});

export const {
  addReservation,
  addReservationFailure,
  addReservationSuccess,
  addReservationMultiplePatients,
  addReservationMultiplePatientsFailure,
  addReservationMultiplePatientsSuccess,
  getListReservation,
  getListReservationFailure,
  getListReservationSuccess,
  cancelReservation,
  cancelReservationFailure,
  cancelReservationSuccess,
  addReview,
  addReviewFailure,
  addReviewSuccess,
} = slice.actions;

export default slice.reducer;
