import { call, put } from "redux-saga/effects";
import {
  authorisationFailure,
  authorisationSuccess,
  editNotificationsPreferencesFailure,
  editNotificationsPreferencesSuccess,
  loginFailure,
  loginSuccess,
  passwordLostFailure,
  passwordLostSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  updateProfileFailure,
  updateProfileSuccess,
} from "src/redux/slices/auth";
import { setSession } from "src/utils/utils";
import {
  requestLogin,
  requestAuthorisation,
  requestGetProfile,
  requestUpdateProfile,
  requestPasswordLost,
  requestEditNotificationsPreferences,
  requestResetPassword,
} from "../requests/auth";

//  Authorisation
export function* handleAuthorisation(action: any): Generator<any, any, any> {
  const { dataToSend } = action.payload;
  try {
    const response = yield call(requestAuthorisation, dataToSend);
    const { access_token, data } = response.data;

    if (response.status === 200) {
      setSession(access_token);
      yield put(authorisationSuccess(data));
    }
  } catch (error) {
    yield put(authorisationFailure(error.message));
  }
}

//  Login
export function* handleLogin(action: any): Generator<any, any, any> {
  const { dataToSend } = action.payload;

  try {
    const response = yield call(requestLogin, dataToSend);

    const { data } = response.data;
    if (response.status === 200) {
      localStorage.setItem("jwtToken", response.data.access_token);
      const responseGetProfile = yield call(requestGetProfile);
      if (responseGetProfile.status === 200) {
        yield put(loginSuccess(responseGetProfile.data.user));
      }
    }
  } catch (error) {
    yield put(loginFailure(error.alert ? error.alert.message : error.error));
  }
}

// update profile
export function* handleUpdateProfile(action: any): Generator<any, any, any> {
  const { translate, toast, dataToSend } = action.payload;

  try {
    const response = yield call(requestUpdateProfile, dataToSend);
    const { data } = response;

    if (response.status === 200) {
      yield put(updateProfileSuccess(data.user));
      toast(translate(`backendMsg.profile_updated_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(updateProfileFailure(error.error_code));
  }
}

//  Lost password
export function* handlePasswordLost(action: any): Generator<any, any, any> {
  const { translate, toast, dataToSend, onNext } = action.payload;

  try {
    const response = yield call(requestPasswordLost, dataToSend);
    const { data } = response;

    if (response.status === 200) {
      yield put(passwordLostSuccess(data));
      toast(translate(`backendMsg.profile_updated_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      if (onNext) {
        onNext();
      }
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(passwordLostFailure(error.error_code));
  }
}

//  Reset password
export function* handleResetPassword(action: any): Generator<any, any, any> {
  const { translate, toast, dataToSend, onNext } = action.payload;

  try {
    const response = yield call(requestResetPassword, dataToSend);
    const { data } = response;

    if (response.status === 200) {
      yield put(resetPasswordSuccess(data));
      toast(translate(`backendMsg.reset_password_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      if (onNext) {
        onNext();
      }
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(resetPasswordFailure(error.error));
  }
}

// Edit Notifications Preferences
export function* handleEditNotificationsPreferences(
  action: any
): Generator<any, any, any> {
  const { translate, toast, dataToSend } = action.payload;
  try {
    const response = yield call(
      requestEditNotificationsPreferences,
      dataToSend
    );
    if (response.status === 200) {
      yield put(
        editNotificationsPreferencesSuccess(dataToSend.pref_notification)
      );
      toast(
        translate(
          dataToSend.pref_notification
            ? `backendMsg.notification_preference_enable_success`
            : `backendMsg.notification_preference_diabled_success`
        ),
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(editNotificationsPreferencesFailure(error.error_code));
  }
}
