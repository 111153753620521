import { call, put } from "redux-saga/effects";
import {
  getListPatientsFailure,
  getListPatientsSuccess,
} from "src/redux/slices/patients";
import { requestPatientsList } from "../requests/patients";

export function* handleGetListPatients(action: any): Generator<any, any, any> {
  try {
    const response = yield call(requestPatientsList);
    if (response.status === 200) {
      yield put(getListPatientsSuccess(response.data.patients));
    }
  } catch (error) {
    yield put(getListPatientsFailure(error.message));
  }
}
