import { FC, ReactNode, useEffect } from "react";
import { Box, alpha, lighten, useTheme } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector } from "src/redux/store";
import { paths } from "src/routes/paths";
import { CLIENT_ID, CLIENT_SECRET, GRANT_TYPE, HOST_API } from "src/config";
import { authorisation } from "src/redux/slices/auth";
import { useDispatch } from "src/redux/store";
import { CHECK_EXPIRY_TOKEN } from "src/utils/const";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("token") || null;
      //  console.log("token", token);
      if (token !== null) {
        // console.log("check expiration");
        const expiration = localStorage.getItem("token_expiration");
        // console.log("token_expiration", expiration);
        const currentTime = Date.now();
        const expirationTime = parseInt(expiration);
        // console.log(currentTime);

        //  console.log(expirationTime);
        if (currentTime >= expirationTime) {
          // console.log("Token has expired");
          const formData = new FormData();
          formData.append("grant_type", GRANT_TYPE);
          formData.append("client_id", CLIENT_ID);
          formData.append("client_secret", CLIENT_SECRET);
          dispatch(
            authorisation({
              dataToSend: formData,
            })
          );
        }
      } else {
        //   console.log("token is null", HOST_API);
        const formData = new FormData();
        formData.append("grant_type", GRANT_TYPE);
        formData.append("client_id", CLIENT_ID);
        formData.append("client_secret", CLIENT_SECRET);
        dispatch(
          authorisation({
            dataToSend: formData,
          })
        );
      }
    }, CHECK_EXPIRY_TOKEN);

    return () => clearInterval(interval);
  }, []);

  /*useEffect(() => {
    if (!authenticated) {
      navigate(paths.auth.login, { replace: true });
    }
  }, [authenticated]);*/

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: "100%",

          ".MuiPageTitle-wrapper": {
            background:
              theme.palette.mode === "dark"
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === "dark"
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`,
          },
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: "relative",
            zIndex: 5,
            display: "block",
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up("lg")]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box display='block'>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
