import { createSlice } from "@reduxjs/toolkit";
import { INotificationsState } from "src/types/notifications";

const initialState: INotificationsState = {
  isLoading: false,
  error: null,
  notifications: [],
  refresh: 0,
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getNotifications(state) {
      state.isLoading = true;
    },
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    getNotificationsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    markNotificationRead(state, action) {
      state.isLoading = true;
    },
    markNotificationReadSuccess(state, action) {
      state.isLoading = false;
      state.refresh += 1;
      state.error = null;
    },
    markNotificationReadFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getNotifications,
  getNotificationsFailure,
  getNotificationsSuccess,
  markNotificationRead,
  markNotificationReadFailure,
  markNotificationReadSuccess,
} = slice.actions;

export default slice.reducer;
