import { createSlice } from "@reduxjs/toolkit";
import { IDriversState } from "src/types/drivers";

const initialState: IDriversState = {
  isLoading: false,
  error: null,
  drivers: [],
};

const slice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    // Get Drivers Around
    getDriversAround(state, action) {
      state.isLoading = true;
    },
    getDriversAroundSuccess(state, action) {
      state.isLoading = false;
      state.drivers = action.payload;
    },

    getDriversAroundFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getDriversAround,
  getDriversAroundFailure,
  getDriversAroundSuccess,
} = slice.actions;

export default slice.reducer;
