import { createSlice } from "@reduxjs/toolkit";
import { IAuthState } from "src/types/auth";

const initialState: IAuthState = {
  isLoading: false,
  loadingOperation: false,
  error: null,
  user: null,
  authenticated: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    init(state, action) {
      state.user = action.payload.user;
      state.authenticated = action.payload.authenticated;
    },

    //  authorisation
    authorisation(state, action) {
      state.isLoading = true;
    },
    authorisationSuccess(state, action) {
      state.isLoading = false;
    },

    authorisationFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //  login
    login(state, action) {
      state.loadingOperation = true;
    },
    loginSuccess(state, action) {
      state.loadingOperation = false;
      state.user = action.payload;
      state.authenticated = true;
    },

    loginFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },

    //  Det user details
    getProfile(state, action) {
      state.isLoading = true;
    },
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
      state.authenticated = true;
    },

    getProfileFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // edit profile
    updateProfile(state, action) {
      state.loadingOperation = true;
    },
    updateProfileSuccess(state, action) {
      state.loadingOperation = false;
      // state.user = action.payload;
      // state.authenticated = true;
      state.user.first_name = action.payload.first_name;
      state.user.last_name = action.payload.last_name;
      state.user.email = action.payload.email;
      state.user.phone = action.payload.phone;
    },

    updateProfileFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },

    // lost password (send to mail )
    passwordLost(state, action) {
      state.loadingOperation = true;
    },
    passwordLostSuccess(state, action) {
      state.loadingOperation = false;
    },
    passwordLostFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },

    // Reset password
    resetPassword(state, action) {
      state.loadingOperation = true;
    },
    resetPasswordSuccess(state, action) {
      state.loadingOperation = false;
    },
    resetPasswordFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },

    editNotificationsPreferences(state, action) {},
    editNotificationsPreferencesSuccess(state, action) {
      state.user.pref_notification = action.payload;
    },
    editNotificationsPreferencesFailure(state, action) {},
  },
});
export const {
  init,
  login,
  loginFailure,
  loginSuccess,
  authorisation,
  authorisationFailure,
  authorisationSuccess,
  getProfile,
  getProfileFailure,
  getProfileSuccess,
  updateProfile,
  updateProfileFailure,
  updateProfileSuccess,
  passwordLost,
  passwordLostFailure,
  passwordLostSuccess,
  editNotificationsPreferences,
  editNotificationsPreferencesFailure,
  editNotificationsPreferencesSuccess,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
} = slice.actions;

export default slice.reducer;
