export const HOST_API = process.env.REACT_APP_API_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_IDD;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE;
export const PORT = process.env.REACT_APP_PORT;
export const KEY_GOOGLE = process.env.REACT_APP_KEY_GOOGLE;

export function apiConfig() {
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const jwtToken =
    typeof window !== "undefined" ? localStorage.getItem("jwtToken") : "";
  if (jwtToken !== "") {
    return {
      headers: {
        //   "Content-Type": "application/json;charset=UTF-8",
        //  "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        jwtToken: `Bearer ${jwtToken}`,
      },
    };
  } else {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
}
