import { call, put } from "redux-saga/effects";
import {
  addReservationFailure,
  addReservationSuccess,
  cancelReservationFailure,
  cancelReservationSuccess,
  getListReservationFailure,
  getListReservationSuccess,
} from "src/redux/slices/reservation";
import {
  requestAddReservation,
  requestAddReservationMultiplePatients,
  requestAddReview,
  requestCancelReservation,
  requestGetReservationList,
  requestResquestCarrier,
} from "../requests/reservation";

// Add reservation
export function* handleAddReservation(action: any): Generator<any, any, any> {
  const { dataToSend, translate, toast, onClose } = action.payload;

  try {
    const response = yield call(requestAddReservation, dataToSend);
    const { data } = response;

    if (response.status === 200) {
      yield put(addReservationSuccess(data));
      toast(translate(`backendMsg.reservation_created_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });

      const responseRequestCarrier = yield call(
        requestResquestCarrier,
        response.data.mission.id,
        { request_carrier_id: dataToSend.request_driver_id }
      );

      onClose();
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(addReservationFailure(error.error_code));
  }
}

// Add reservation with multiple patients
export function* handleAddReservationMultiplePatient(
  action: any
): Generator<any, any, any> {
  const { dataToSend, translate, toast, onClose } = action.payload;
  try {
    const response = yield call(
      requestAddReservationMultiplePatients,
      dataToSend
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(addReservationSuccess(data));
      toast(translate(`backendMsg.reservation_created_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      for (let i = 0; i < response.data.mission.length; i += 1) {
        const responseRequestCarrier = yield call(
          requestResquestCarrier,
          response.data.mission[i].id,
          { request_carrier_id: dataToSend.mission_list[0].request_driver_id }
        );
      }
      onClose();
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(addReservationFailure(error.error_code));
  }
}
// Get list des reservations
export function* handleGetListReservation(
  action: any
): Generator<any, any, any> {
  try {
    const response = yield call(requestGetReservationList);

    if (response.status === 200) {
      yield put(getListReservationSuccess(response.data.missions));
    }
  } catch (error) {
    yield put(getListReservationFailure(error.message));
  }
}

// Cancel reservation
export function* handleCancelReservation(
  action: any
): Generator<any, any, any> {
  const { id, translate, toast, onClose } = action.payload;

  try {
    const response = yield call(requestCancelReservation, id);
    const { data } = response;

    if (response.status === 200) {
      yield put(cancelReservationSuccess(data));
      toast(translate(`backendMsg.reservation_canceled_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(cancelReservationFailure(error.error_code));
  }
}

// Add review
export function* handleAddReview(action: any): Generator<any, any, any> {
  const { dataToSend, translate, toast, id } = action.payload;
  try {
    const response = yield call(requestAddReview, id, dataToSend);
    const { data } = response;
    if (response.status === 200) {
      yield put(addReservationSuccess(data));
      toast(translate(`backendMsg.review_created_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  } catch (error) {
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(addReservationFailure(error.error_code));
  }
}
